@font-face {
  font-family: architext;
  src: url('https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/architext.ttf');
}

@font-face {
  font-family: brandin;
  src: url('https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/BrandinkSans.otf');
}

html, body{
  height: 100%;
  margin: 0;
  font-family: brandin, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: .9em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.side-scroll {
  overflow-x: auto;
}

.root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

hr {
  width: 100%;
  border: 0;
  height: 2px;
  background-color: darkgoldenrod;
}

.page-header{
  font-family: architext;
  font-size: 3em;
  font-weight: bold;
}

.fun {
  font-family: architext;
  font-size: 1.8em;
}

.enlarge {
  font-size: 1.2em;
}

.border {
  border: 2px darkgoldenrod;
  border-style: ridge;
}

* {
  box-sizing: border-box;
}

.validation {
  width: 100%;
  position: relative;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.validation .validationtext {
  width: 100%;
  min-width: 160px;
  overflow-y: visible;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -50%;
}

.text-btn {
  background-image: url('https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/btn-texture.jpg');
  color: white;
  font-family: brandin;
  font-size: 1em;
  font-weight: bold;
  border-color: goldenrod;
  border-width: 2px;
  border-style: outset;
  cursor: pointer;
  text-align: center;
  margin: 5px;
  text-decoration: none;
}

.text-btn:hover {
  background: goldenrod;
}

.text-btn-s {
  background-image: url('https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/btn-texture.jpg');
  color: white;
  font-family: brandin;
  font-size: .8em;
  border-color: goldenrod;
  border-width: 2px;
  border-style: outset;
  cursor: pointer;
  text-align: center;
  margin: 2px;
}

.text-btn-s:hover {
  background: goldenrod;
}

.x-btn {
  background: none;
  font-family: architext;
  font-size: 1.5em;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.x-btn:hover {
  color: red;
}

.discrete-btn{
  background: none;
  font-size: 1.5em;
  border: none;
  cursor: pointer;
}

.discrete-btn:hover {
  color: red;
}

.modal {
  position: fixed;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
  z-index: 1; /* makes the modal appear on top, may need to increase if other popups are present */
  background-color: rgba(0,0,0,.45); /* darken the screen */
}

.modal-body{
  margin-top: 10%;
  animation-name: fadeIn;
  animation-duration: .4s;
}

@keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1}
}

.end {
  justify-content: end;
  margin-left: auto;
}

.center {
  justify-content: center;
}

.virt-center {
  align-content: center;
}

.text-center {
  text-align: center;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

[class*="col-"] {
  float: left;
  padding: 10px;
}

.flex-container {
  height: fit-content;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  background-image: url('https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/container_background.jpg');
}


.flex-row {
  flex-grow: 0;
  background-clip: content-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  padding-bottom: 15px;
}

.flex-row-right {
  background-clip: content-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 15px;
}

.flex-column {
  background-clip: content-box;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.hidden-table {
  border: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .col-xs-1 {width: 8.33%;}
  .col-xs-2 {width: 16.66%;}
  .col-xs-3 {width: 25%;}
  .col-xs-4 {width: 33.33%;}
  .col-xs-5 {width: 41.66%;}
  .col-xs-6 {width: 50%;}
  .col-xs-7 {width: 58.33%;}
  .col-xs-8 {width: 66.66%;}
  .col-xs-9 {width: 75%;}
  .col-xs-10 {width: 83.33%;}
  .col-xs-11 {width: 91.66%;}
  .col-xs-12 {width: 100%;}
  .small-text {font-size: .8em;}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .col-s-1 {width: 8.33%;}
  .col-s-2 {width: 16.66%;}
  .col-s-3 {width: 25%;}
  .col-s-4 {width: 33.33%;}
  .col-s-5 {width: 41.66%;}
  .col-s-6 {width: 50%;}
  .col-s-7 {width: 58.33%;}
  .col-s-8 {width: 66.66%;}
  .col-s-9 {width: 75%;}
  .col-s-10 {width: 83.33%;}
  .col-s-11 {width: 91.66%;}
  .col-s-12 {width: 100%;}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .col-m-1 {width: 8.33%;}
  .col-m-2 {width: 16.66%;}
  .col-m-3 {width: 25%;}
  .col-m-4 {width: 33.33%;}
  .col-m-5 {width: 41.66%;}
  .col-m-6 {width: 50%;}
  .col-m-7 {width: 58.33%;}
  .col-m-8 {width: 66.66%;}
  .col-m-9 {width: 75%;}
  .col-m-10 {width: 83.33%;}
  .col-m-11 {width: 91.66%;}
  .col-m-12 {width: 100%;}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .col-1 {width: 8.33%;}
  .col-2 {width: 16.66%;}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 41.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;}
}

.tab {
  border-width: 2px 2px 2px 2px;
  border-color: darkgoldenrod;
  border-style: ridge;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: none;
}

.active-tab {
  background: darkgoldenrod !important;
  border-width: 2px 2px 2px 2px;
  border-color: darkgoldenrod;
  border-style: ridge;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: darkgoldenrod;
}

textarea {
  width: 100%;
  height: 120px;
}

.float-right {
  float: right !important;
}

.padding-right {
  padding-right: 10px;
}

.padding-left {
  padding-left: 10px;
}

.padding-top {
  padding-top: 10px;
}

.padding-bottom {
  padding-bottom: 10px;
}

.padding {
  padding: 10px;
}

.padding-s {
  padding: 2px;
}

.margin {
  margin: 10px;
}

.no-bottom-padding {
  padding-bottom: 0px !important;
}

.no-top-padding {
  padding-top: 0px !important;
}

.no-virt-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.no-left-padding {
  padding-left: 0px !important;
}

.no-padding {
  padding: 0px !important;
}

.no-margin  {
  margin: 0px !important;
}

.no-left-margin {
  margin-left: 0px !important;
}

.page {
  overflow-y: auto;
  height: 100%;
}

ul {
  list-style-type: none;
}

.hidden {
  display: none;
}

.book-formatted table tr:nth-child(odd) td{
  background-color: white;
  border: none;
  text-align: center;
}
.book-formatted table tr:nth-child(even) td{
  background-color: #aeaaaa;
  border: none;
  text-align: center;
}

.book-formatted table th {
  color: white;
  background-color: black;
  border: none;
  text-align: center;
}

.book-formatted table {
  border: none;
}

.site-formatted table tr:nth-child(odd) td{
  border-color: goldenrod;
  border-style: ridge;
  border-width: 1px 0px 1px 0px;
  text-align: center;
}
.site-formatted table tr:nth-child(even) td{
  color: black;
  background-image: url('https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/table-row.png');
  border-color: goldenrod;
  border-style: ridge;
  border-width: 1px 0px 1px 0px;
  text-align: center;
}

.site-formatted table th {
  font-family: brandin;
  color: white;
  background-image: url('https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/navbar.jpg');
  border-color: goldenrod;
  border-style: ridge;
  border-width: 1px 0px 1px 0px;
  text-align: center;
}

.site-formatted table {
  border-color: goldenrod;
  border-style: ridge;
}