/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
      /* Style the navigation menu */
      .topnav {
        overflow: hidden;
        background-image: url('https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/navbar.jpg');
        position: relative;
      }
  
      /* Style navigation menu links */
      .topnav a {
        color: white;
        padding: 14px 16px;
        text-decoration: none;
        font-size: 1em;
        display: block;
        border: 1px goldenrod solid;
      }
  
      /* Style the hamburger menu */
      .topnav button.icon {
        background-image: url('https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/container_background.jpg');
        border: none;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
      }
  
      /* Add a grey background color on mouse-over */
      .topnav a:hover {
        background-color: goldenrod;
      }
  
      /* Style the active link (or home/logo) */
      .active {
        color: white;
      }
      
      .button {
        text-align: center;
      }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
      /* Style the navigation menu */
      .topnav {
        overflow: hidden;
        background-image: url('https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/navbar.jpg');
        position: relative;
      }
  
      /* Style navigation menu links */
      .topnav a {
        color: white;
        padding: 14px 16px;
        text-decoration: none;
        font-size: 1em;
        display: block;
        border: 1px goldenrod solid;
      }
  
      /* Style the hamburger menu */
      .topnav button.icon {
        background-image: url('https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/container_background.jpg');
        border: none;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
      }
  
      /* Add a grey background color on mouse-over */
      .topnav a:hover {
        background-color: goldenrod;
      }
  
      /* Style the active link (or home/logo) */
      .active {
        color: white;
      }

      .button {
        text-align: center;
      }
  }
  
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    /* Style the navigation menu */
    .topnav {
      overflow: hidden;
      background-image: url('https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/navbar.jpg');
      position: relative;
    }

    /* Style navigation menu links */
    .topnav a {
      color: white;
      padding: 14px 16px;
      text-decoration: none;
      font-size: 1em;
      display: block;
      border: 1px goldenrod solid;
    }

    /* Style the hamburger menu */
    .topnav button.icon {
      background-image: url('https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/container_background.jpg');
      border: none;
      display: block;
      position: absolute;
      right: 0;
      top: 0;
    }

    /* Add a grey background color on mouse-over */
    .topnav a:hover {
      background-color: goldenrod;
    }

    /* Style the active link (or home/logo) */
    .active {
      color: white;
    }

    .button {
      background-image: url('https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/container_background.jpg');
      text-align: center;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .navbar {
        height: 53px;
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: visible;
        background-image: url('https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/navbar.jpg');
        position: fixed;
        z-index: 2;
    }
    .height-cap {
      max-height: 53px;
  }
    
  li a {
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      width: 100%;
  }
  
  li .username {
      display: block;
      color: white;
      text-align: center;
      align-items: center;
      padding: 14px 16px;
      text-decoration: none;
  }
  
  .nav-item:hover {
      background-image: url('https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/container_background.jpg');
      color: black;
  }
  
  
  .button {
      background-image: url('https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/container_background.jpg');
      border: solid;
      border-width: 1px;
      border-color: #333;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
  }
  
  .dropdown {
      position: relative;
      display: inline-block;
    }
    
    .dropdown-content {
      display: none;
      position: absolute;
      background-image: url('https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/navbar.jpg');
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      padding: 5px 5px;
      z-index: 1;
    }
  
    .dropdown:hover .dropdown-content {
      display: block;
    }
  }