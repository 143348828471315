/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .wrapper {
        padding: 0px 0px 0px 0px;
        display: flex;
        justify-content: center;
        background-size: 100% 100%;
        overflow-y: auto;
        height: 100%;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 601px) {
    .wrapper {
        padding: 0px 0px 0px 0px;
        display: flex;
        justify-content: center;
        background-size: 100% 100%;
        overflow-y: auto;
        height: 100%;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .wrapper {
        padding: 10px 20px 10px 20px;
        display: flex;
        justify-content: center;
        background-image: url('https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/background.jpg');
        background-repeat: no-repeat;
        background-attachment: fixed; 
        background-size: 100% 100%;
        overflow-y: auto;
        height: 100%;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .wrapper {
        padding: 65px 20px 10px 20px;
        display: flex;
        justify-content: center;
        background-image: url('https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/background.jpg');
        background-repeat: no-repeat;
        background-attachment: fixed; 
        background-size: 100% 100%;
        overflow-y: auto;
        height: 100%;
    }
  }
