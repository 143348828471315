.logout_button {
    background-image: url('https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/container_background.jpg');
    border: solid;
    border-width: 1px;
    border-color: #333;
    color: black;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: brandin;
}